<template>
    <div class="container">

        <header class="bg-gray-50 pt-4 mx-4">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                <div class="min-w-0 flex-1">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol role="list" class="flex items-center space-x-4">
                            <li>
                                <div>
                                    <a href="#" class="text-sm font-medium text-gray-500 hover:text-gray-700">Home</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="h-5 w-5 flex-shrink-0 text-gray-400"
                                        x-description="Heroicon name: mini/chevron-right"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                        aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                    <a href="#"
                                        class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Companies</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
                        <div class="flex flex-wrap items-center justify-between mb-8">
                            <h2 class="mr-10 text-4xl font-bold leading-none md:text-5xl">
                                My companies
                            </h2>
                            <div class="block pb-1 mt-2">
                                <v-btn color="secondary rounded-lg" @click="addNewCompany">
                                    <v-icon>{{ icons.mdiShapeRectanglePlus }}</v-icon>
                                    <span>New Company</span>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="flex flex-col justify-center w-full max-w-6xl min-h-screen py-10">
            <div class="flex flex-wrap" v-if="isCompanyLoaded">
                <div class="w-full mb-8 sm:w-1/2 px-4 lg:w-1/3 flex flex-col" v-for="(item, index) in listenterprise"
                    :key="index">
                    <!-- <img src="@/assets/images/avatars/img-xl.svg" alt="Card img"
                        class="object-cover object-center w-full h-48" /> -->
                    <div class="flex flex-grow">
                        <div class="triangle"></div>
                        <div class="flex flex-col justify-between px-4 py-6 bg-white border border-gray-400 text">
                            <div>
                                <a href="#"
                                    class="inline-block mb-4 text-xs font-bold capitalize border-b-2 border-blue-600 hover:text-blue-600"
                                    @click="showCompanyDasboard(item, 'edit')">Edit settings</a>
                                <a
                                    class="block mb-4 text-2xl font-black leading-tight hover:underline hover:text-blue-600">
                                    {{ item.company.name }}
                                </a>

                            </div>
                            <div>
                                <a @click="showCompanyDasboard(item, '')"
                                    class="inline-block pb-1 mt-2 text-base font-black text-blue-600 uppercase border-b border-transparent hover:border-blue-600">OPEN
                                    -></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap" v-else>
                <v-skeleton-loader v-for="i in 6" :key="i" class="w-auto mb-8 sm:w-1/2 px-4 lg:w-1/3" max-width="100%"
                    type="article">
                </v-skeleton-loader>
            </div>
        </section>

        <!-- <v-data-table :headers="headers" :items="listenterprise" :search="search" :loading="isCompanyLoaded"
            loading-text="Loading your companies... Please wait" sort-by="object" class="elevation-1 row-pointer"
            style="cursor: pointer" @click:row="(item) => showCompanyDasboard(item, '')" flat>
            <template v-slot:item.company="{ item }">
                <v-col class="d-flex flex-row align-center">
                    <v-avatar size="50" class="mr-3">
                        <v-img src="@/assets/images/avatars/profil.svg" cover></v-img>
                    </v-avatar>
                    <h4 class="text-capitalize">{{ item.company.name }}</h4>
                </v-col>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-menu bottom origin="center center" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="subsecondary" v-bind="attrs" v-on="on">
                            <v-icon small @click.color="secondary">
                                {{ icons.mdiDotsHorizontal }}
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-text class="pa-2 d-flex flex-column">
                            <v-btn text small block @click="showCompanyDasboard(item, '')">Open</v-btn>
                            <v-btn text small block @click="showCompanyDasboard(item, 'edit')">settings</v-btn>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </template>
        </v-data-table> -->
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiDotsHorizontal, mdiShapeRectanglePlus } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import CardHeader from '@/views/dashboard/components/CardHeader.vue'
var md5 = require("md5")

export default {
    props: {
        showhead: {
            type: Boolean,
            default: true
        }
    },
    components: { CardHeader },
    data() {
        return {
            topheaders: {
                titlepage: 'MY COMPANIES',
                icon: null,
            },
            loading: false,
            isCompanyLoaded: false,
            listenterprise: [],
        }
    },
    mounted() {
        this.isCompanyLoaded = false
        Drequest.api(`lazyloading.enterprise?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.listenterprise.push({
                            id: value.id,
                            company: {
                                name: value.name,
                                logo: value.logo,
                            },
                            type: value.type.designation,
                            about: value.about,
                            created_at: value.created_at,
                            website: value.website,
                        })
                    });
                    this.isCompanyLoaded = true
                }
                else {
                    this.isCompanyLoaded = false
                }
            })
            .catch((err) => {
                alert('Server error')
                this.isCompanyLoaded = false
            });

    },
    methods: {
        highlightClickedRow(value) {
            const tr = value.target.parentNode;
            tr.classList.add('primary');
        },
        addNewCompany() {
            this.$router.push({ name: 'user.create.company' })
        },
        showCompanyDasboard(item, op) {
            const companie = this.listenterprise.find(element => element.id == item.id);
            this.$store.commit("setCompany", {
                id: companie.id,
                about: companie.about,
                logo: companie.company.logo,
                name: companie.company.name,
                // activity_sector: companie.activity_sector,
                email: companie.email,
                // phonenumber: companie.phonenumber,
                // website: companie.website,
                // form: companie.enterprise_form,
                type: companie.type,
            });
            if (op === 'edit') {
                this.$router.push({ name: 'company.account', params: { company: companie.company.name } })
            }
            else {
                this.$router.push({ name: 'company.home', params: { company: companie.company.name } })
            }
            // this.$router.push({ name: 'company', params: { ids: this.listenterprise.indexOf(item), id: md5(this.listenterprise.indexOf(item)) } })
        },
        deleteItem(item) {
            this.editedIndex = this.listenterprise.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.listenterprise.splice(this.editedIndex, 1)
            this.closeDelete()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.listenterprise[this.editedIndex], this.editedItem)
            } else {
                this.listenterprise.push(this.editedItem)
            }
            this.close()
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    setup(props) {
        // button data-table action
        const btnSingle = ref(false)
        const btnRow = ref(false)
        const title = props.title
        const icons = { mdiMagnify, mdiEye, mdiDotsHorizontal, mdiShapeRectanglePlus }
        let search = ref('')
        const headers = [
            {
                text: 'COMPANY',
                value: 'company',
                align: 'start',
                sortable: true,
            },
            {
                text: 'CATEGORY',
                value: 'type',
            },
            { text: 'WEBSITE', value: 'website' }, ,
            {
                text: 'CREATED AT',
                value: 'created_at',
            },
            { text: 'Actions', value: 'actions', sortable: false },
        ]
        return {
            // button data-table action
            btnRow, btnSingle,
            title,
            icons,
            search,
            headers
        }
    },
}
</script>

<style lang="scss" scoped>
//.row-pointer>.v-data-table__wrapper>table>tbody>tr:hover {
//   cursor: pointer;
//}

.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}
</style>